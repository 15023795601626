import React, { useState, useRef, useEffect } from "react";
import {
  Send,
  Mic,
  MicOff,
  Play,
  Image as ImageIcon,
  HelpCircle,
} from "lucide-react";

const SYSTEM_PROMPT = `
Je bent een vriendelijke en geduldige studieassistent voor een kind. Je taak is om te helpen bij huiswerk en vragen te beantwoorden over de afbeelding die is geüpload. Volg deze richtlijnen:

1. Analyseer de afbeelding zorgvuldig en beschrijf kort wat je ziet, vooral als het gaat om huiswerk of een oefening.

2. Als er een duidelijke vraag of probleem zichtbaar is, leg dit uit in eenvoudige taal die een kind kan begrijpen.

3. Geef geen directe antwoorden, maar help het kind om zelf tot de oplossing te komen:
   - Stel vragen die het kind helpen na te denken over het probleem.
   - Geef hints of aanwijzingen die in de goede richting leiden.
   - Leg relevante concepten uit op een kindvriendelijke manier.

4. Als er geen specifieke vraag zichtbaar is, vraag dan wat het kind niet begrijpt of waar hulp bij nodig is.

5. Moedig het kind aan en geef complimenten voor de inzet en het denken.

6. Als er meer context nodig is, vraag dan vriendelijk om meer informatie of een duidelijkere foto.

7. Blijf geduldig en positief, ook als het kind moeite heeft met het begrijpen.

8. Het onderwerp mag alleen gaan over huiswerk en studiegerelateerde vragen. Het gaat om huiswerk op de basisschool. Jonger dan 12 jaar.

9. Als het gaat om rekenen en cijfers bij elkaar optellen. Beveel dan de split methode aan. Voorbeeld:
4 + 9 => Dan ga je eerst naar de 10. Dat is dus 9 + 1. Je houdt dan 3 over. Die voeg je erna toe en dan kom je uit op 13.

Geef de antwoorden niet in een keer. Maar help het kind onderweg om naar een tiental te gaan. Als ze meerdere keren dezelfde
vraag stellen, geef dan een uitgebreider antwoord.

Onthoud, het doel is om het kind te helpen leren en zelfvertrouwen op te bouwen bij het oplossen van problemen.
`.trim();

const GAME_PROMPT = `
Je speelt nu het spel "10 vragen" over personages uit het Mario-universum. Je hebt een personage in gedachten, en de speler moet raden wie het is door ja/nee-vragen te stellen. Volg deze regels:

1. Kies een willekeurig personage uit het Mario-universum.
2. Beantwoord de vragen van de speler alleen met "Ja", "Nee", of "Ik weet het niet" als het echt niet duidelijk is.
3. Houd bij hoeveel vragen er zijn gesteld.
4. Als de speler het juiste personage raadt, feliciteer ze dan en beëindig het spel.
5. Als de speler 10 vragen heeft gesteld zonder het personage te raden, onthul dan wie het was en beëindig het spel.
6. Wees enthousiast en moedig de speler aan!
7. Je mag ook een hint geven als erom gevraagd wordt.

Begin het spel door te zeggen: "Ik heb een personage uit het Mario-universum in gedachten. Stel me ja/nee-vragen om te raden wie het is! Je hebt 10 vragen."
`.trim();

const MARIO_CHARACTERS = [
  "Mario",
  "Luigi",
  "Princess Peach",
  "Bowser",
  "Yoshi",
  "Toad",
  "Wario",
  "Waluigi",
  "Donkey Kong",
  "Diddy Kong",
  "Rosalina",
  "Daisy",
  "Bowser Jr.",
  "Kamek",
  "Goomba",
  "Koopa Troopa",
  "Boo",
  "Shy Guy",
  "Birdo",
  "Toadette",
];

const ChatInterface = () => {
  const [messages, setMessages] = useState([
    {
      text: "Hallo Hugo! Ik kan je helpen met je huiswerk! Wat wil je weten?",
      isBot: true,
      audio: null,
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying20Questions, setIsPlaying20Questions] = useState(false);

  const [questionCount, setQuestionCount] = useState(0);
  const [selectedCharacter, setSelectedCharacter] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const startRecording = async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error("Media devices API not supported in this browser.");
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        console.log(audioBlob);
        await sendAudioToWhisper(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "I'm sorry, but I couldn't access your microphone. Please make sure you're using a secure connection (HTTPS) and that you've granted microphone permissions.",
          isBot: true,
        },
      ]);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        console.log(
          "Audio recording stopped, blob created with size:",
          audioBlob.size,
          "bytes",
        );
        await sendAudioToWhisper(audioBlob);
      };
    }
  };
  const sendAudioToWhisper = async (audioBlob) => {
    setIsLoading(true);
    try {
      console.log("Sending audio to server, size:", audioBlob.size, "bytes");
      console.log("Audio type:", audioBlob.type);

      const response = await fetch("/api/transcribe", {
        method: "POST",
        body: audioBlob,
        headers: {
          "Content-Type": audioBlob.type,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }

      const data = await response.json();

      if (data.text) {
        // Don't set the input message or add it to the messages
        // Just send the transcribed text to the chatbot
        await handleSendMessage(data.text);
      }
    } catch (error) {
      console.error("Error sending audio to Whisper:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "Sorry, there was an error transcribing your audio. Please try again.",
          isBot: true,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const start20QuestionsGame = async () => {
    setIsPlaying20Questions(true);
    setQuestionCount(0);
    const character =
      MARIO_CHARACTERS[Math.floor(Math.random() * MARIO_CHARACTERS.length)];
    setSelectedCharacter(character);
    await handleSendMessage("Start het 10 vragen spel", null, GAME_PROMPT);
  };

  const [latestAudioUrl, setLatestAudioUrl] = useState(null);

  const currentAudioRef = useRef(null);

  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  useEffect(() => {
    if (latestAudioUrl) {
      playAudio(latestAudioUrl);
    }
  }, [latestAudioUrl]);

  const handleSendMessage = async (
    messageToSend = inputMessage,
    imageFile = null,
    customPrompt = null,
  ) => {
    if (messageToSend.trim() !== "" || imageFile) {
      let userMessage = { text: messageToSend, isBot: false };
      let imageBase64 = null;

      if (imageFile) {
        imageBase64 = await convertToBase64(imageFile);
        userMessage.image = URL.createObjectURL(imageFile);
      }

      setMessages((prev) => {
        const newMessages = [...prev, userMessage];
        return newMessages.slice(-50);
      });

      setInputMessage("");
      setIsLoading(true);

      try {
        // Existing chat request
        const response = await fetch("/api/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: [
              { role: "system", content: customPrompt || SYSTEM_PROMPT },
              ...messages.slice(-49).map((msg) => ({
                role: msg.isBot ? "assistant" : "user",
                content: msg.text,
              })),
              {
                role: "user",
                content: [
                  { type: "text", text: messageToSend },
                  imageBase64
                    ? {
                        type: "image_url",
                        image_url: {
                          url: `data:image/jpeg;base64,${imageBase64}`,
                        },
                      }
                    : null,
                ].filter(Boolean),
              },
            ],
            isPlaying20Questions: isPlaying20Questions,
            questionCount: questionCount,
            selectedCharacter: selectedCharacter,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Text-to-speech request for chatbot's response
        const ttsResponse = await fetch("/api/tts", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: data.content }),
        });

        if (!ttsResponse.ok) {
          throw new Error("TTS request failed");
        }

        const audioBlob = await ttsResponse.blob();
        const audioUrl = URL.createObjectURL(audioBlob);

        setMessages((prev) => {
          const newMessages = [
            ...prev,
            { text: data.content, isBot: true, audio: audioUrl },
          ];
          return newMessages.slice(-50);
        });

        setLatestAudioUrl(audioUrl);

        if (isPlaying20Questions) {
          setQuestionCount((prev) => prev + 1);
          if (
            data.content.toLowerCase().includes("gefeliciteerd") ||
            questionCount >= 10
          ) {
            setIsPlaying20Questions(false);
            setMessages((prev) => {
              const newMessages = [
                ...prev,
                {
                  text: `Het spel is afgelopen! Het personage was ${selectedCharacter}.`,
                  isBot: true,
                },
              ];
              return newMessages.slice(-50);
            });
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setMessages((prev) => {
          const newMessages = [
            ...prev,
            {
              text: "Sorry, I couldn't process that request. Please try again.",
              isBot: true,
            },
          ];
          return newMessages.slice(-50);
        });
      }

      setIsLoading(false);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 1024; // Je kunt deze waarde aanpassen
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.7,
          ); // Je kunt de kwaliteit aanpassen (0.7 = 70%)
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedFile = await compressImage(file);
      handleSendMessage(inputMessage, compressedFile);

      // Clear the file input after the upload
      fileInputRef.current.value = null;
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleVoiceInput = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    } else {
      setMessages((prev) => [
        ...prev,
        {
          text: "Voice recording is not supported in your browser. Please type your question instead.",
          isBot: true,
        },
      ]);
    }
  };

  const playAudio = (audioUrl) => {
    if (currentlyPlayingAudio === audioUrl) {
      if (isAudioPlaying) {
        currentAudioRef.current.pause();
        setIsAudioPlaying(false);
      } else {
        currentAudioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
        setIsAudioPlaying(true);
      }
    } else {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
      }
      const audio = new Audio(audioUrl);
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
      currentAudioRef.current = audio;
      setCurrentlyPlayingAudio(audioUrl);
      setIsAudioPlaying(true);

      audio.onended = () => {
        setIsAudioPlaying(false);
        setCurrentlyPlayingAudio(null);
      };
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50 font-sans">
      <header className="bg-blue-600 text-white p-4 shadow-md">
        <h1 className="text-2xl font-medium">Hugo's studie assistent</h1>
      </header>
      <div className="flex-1 overflow-y-auto p-6 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.isBot ? "justify-start" : "justify-end"}`}
          >
            <div
              className={`max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-2xl px-4 py-3 shadow-sm
                ${
                  message.isBot
                    ? "bg-white text-gray-800"
                    : "bg-blue-500 text-white"
                }`}
            >
              {message.image && (
                <img
                  src={message.image}
                  alt="Uploaded"
                  className="mb-2 rounded-lg"
                />
              )}
              <p
                className="text-sm md:text-base"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {message.text}
                {message.audio && (
                  <button
                    onClick={() => playAudio(message.audio)}
                    className="ml-2 text-blue-500 hover:text-blue-700"
                  >
                    <Play size={16} />
                  </button>
                )}
              </p>
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-white text-gray-800 rounded-2xl px-4 py-3 shadow-sm">
              <p className="text-sm md:text-base">Even nadenken...</p>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-white shadow-md">
        <div className="flex items-center max-w-4xl mx-auto">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type of spreek je bericht..."
            className="flex-1 border-0 bg-gray-100 rounded-full px-6 py-3 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm md:text-base"
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <button
            onClick={handleVoiceInput}
            className={`mr-2 rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-colors duration-200 ${
              isRecording
                ? "bg-red-500 text-white"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {isRecording ? <MicOff size={20} /> : <Mic size={20} />}
          </button>
          <button
            onClick={triggerImageUpload}
            className="mr-2 bg-green-500 text-white rounded-full p-3 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 transition-colors duration-200"
          >
            <ImageIcon size={20} />
          </button>
          <button
            onClick={start20QuestionsGame}
            className="mr-2 bg-yellow-500 text-white rounded-full p-3 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition-colors duration-200"
          >
            <HelpCircle size={20} />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageUpload}
            accept="image/*"
            style={{ display: "none" }}
          />
          <button
            onClick={() => handleSendMessage()}
            className="bg-blue-500 text-white rounded-full p-3 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-colors duration-200"
            disabled={isLoading}
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
